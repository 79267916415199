@media (min-width: 20px) and (max-width: 420px) {
  /*.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper{
    width: 0px;
  }*/
  .padingMedia {
    padding-top: 50px;
  }
}

.cardBlue{
  background-color: #ffffff;
  color: #e7e9ec;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  border-color: #90caf925;
  background-color: #1e88e5;
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 20px;
}

/*input > label > fieldset */
.white label {
  color: rgb(255 255 255 / 87%) !important;
  border-color: #fff !important;
}
.white input {
  color: rgb(255 255 255 / 87%) !important;
  border-color: #fff !important;
}
.white fieldset {
  color: rgb(255 255 255 / 87%) !important;
  border-color: #fff !important;
}


.cardLilac{
  background-color: #ffffff;
  color: #364152;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  border-color: #90caf925;
  background-color: #5e35b1;
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 20px;
}

.cardLilac select {
  color: rgb(255 255 255 / 87%) !important;
}
.cardLilac option {
  background-color: #5e35b1 !important;
}

.NativeSelect-White{
  border: 0 !important;
}

.cardProfile{
  background-color: #ffffff;
  color: #364152;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  border-color: #90caf925;
  background-color: #13a438;
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 20px;
}